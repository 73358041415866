import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/AuthProvider";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  positions: {
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export const BlueSkySwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#6fa8dc",
    "&:hover": {
      backgroundColor: alpha("#6fa8dc", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#6fa8dc",
  },
}));

export default function GestionPermissions({ data, state, handleChange }) {
  const { permissionsRole } = useContext(AuthContext);
  const classes = useStyles();

  return (
    <div className={classes.positions}>
      {data.label}
      <BlueSkySwitch
        onClick={() => handleChange(data.name)} /* Switches hijos de configuración */
        checked={permissionsRole[data.name]}
      >
        {" "}
      </BlueSkySwitch>
    </div>
  );
}
