import React, { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { AuthContext } from '../../context/AuthProvider';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar } from '@mui/material';
//query
import { DISABLE_ROLE_PERMISSION, CREATE_ROLE_PERMISSION, EDIT_ROLE_PERMISSION } from '../../controllers/rolesControllers';
import { useMutation } from '@apollo/client';
import { AlertComponent } from './AlertComponent';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  
  title: {
    display: 'flex',
    // backgroundColor: "#DD9233",
    height: '3em',
  },
  button: {
    backgroundColor: '#fff',
  },
}));

export const ModalAlertChangesRoles = ({ refresh, data, roleIdd, openChangeRoleStatusFromToggle, setOpenChangeRoleStatusFromToggle, checkedToggle, setCheckedToggle }) => {
  const { t } = useTranslation();
  const [disabled] = useMutation(DISABLE_ROLE_PERMISSION);
  const [creations] = useMutation(CREATE_ROLE_PERMISSION);
  const [edits] = useMutation(EDIT_ROLE_PERMISSION);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [styleMessage, setStyleMessage] = useState({});
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { enabledOrDisabledRole, setEnabledOrDisabledRole, PermissionsConfig, permissionsRole, setPermissionsRoles, createOrEditRole, nameRol, setNameRol, createRole, setCreateRole, roleId, roles, roleState, setRoleState } = useContext(AuthContext);

  const handleCloseAlert = () => {
    setTimeout(function () {
      setOpen(false);
    }, 3000);
  };

  function createEditAndDisabledRole() {
    if (nameRol !== '' || null || undefined) {
      if (createOrEditRole === t("VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.MODAL_CREATE_TOOLTIP")) {
        try {
          creations({
            variables: {
              nameRole: nameRol,
              components: [{ permissionsRole }],
            },
          })
            .then((resul) => {
              if (resul.data.CreateRoleAndPermission === false) {
                setMessage('No se puede crear un nombre de rol ya en uso');
                setStyleMessage('error');
                setOpen(true);
              } else {
                setMessage('Se creo correctamente el Rol');
                setStyleMessage('success');
                setOpen(true);
                handleCloseAlert();
                setTimeout(function () {
                  setCreateRole(false);
                  refresh();
                }, 3000);
              }
            })
            .catch((err) => {
              setOpen(true);
            });
        } catch (err) {
          setOpen(true);
        }
      }
    }
    if (createOrEditRole ===  t("VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.MODAL_EDIT_TOOLTIP")) {
      try {
        edits({
          variables: {
            id: roleId,
            name: nameRol,
            components: [{ permissionsRole }],
          },
        })
          .then((resul) => {
            setMessage('Se edito correctamente el Rol');
            setStyleMessage('success');
            setOpen(true);
            handleCloseAlert();
            setTimeout(function () {
              setEnabledOrDisabledRole(false);
              setCreateRole(false);
              refresh();
            }, 3000);
          })
          .catch((err) => {
            console.log(err);
            setMessage('No se puede crear un nombre de rol ya en uso ');
            setStyleMessage('error');
            setOpen(true);
          });
      } catch (err) {
        setOpen(true);
      }
    } else {
      setMessage('Error el campo no puede ir vacio');
      setStyleMessage('error');
      setOpen(true);
      handleCloseAlert();
    }
    try {
      disabled({
        variables: {
          id: roleIdd,
          enabled: Boolean(!checkedToggle),
        },
      })
        .then((result) => {
          setEnabledOrDisabledRole(false);
          setCheckedToggle(!checkedToggle);
          window.location.reload();
          refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }

  const handleClose = () => {
    setEnabledOrDisabledRole(false);
    setOpenChangeRoleStatusFromToggle(false);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={enabledOrDisabledRole || openChangeRoleStatusFromToggle}
      >
        <div className={classes.title}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            ¿Estas seguro de realizar esta acción?
          </DialogTitle>

          <CloseOutlinedIcon
            sx={{ color: '#000' }}
            onClick={handleClose}
          />
        </div>

        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '88%',
            padding: '1em',
          }}
        >
          <Button
            size="small"
            sx={{
              color: '#fff',
              backgroundColor: '#E53444',
              '&:hover': { backgroundColor: '#DF172A' },
            }}
            onClick={handleClose}
          >
            {' '}
            Cancelar
          </Button>

          <Button
            size="small"
            sx={{
              color: '#fff',
              backgroundColor: '#22a95c',
              '&:hover': { backgroundColor: '#336535' },
            }}
            onClick={() => createEditAndDisabledRole()}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <AlertComponent
        open={open}
        message={message}
        styleMessage={styleMessage}
      />
    </>
  );
};
