import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { makeStyles } from "@material-ui/core/styles";
import ComponentsProject from "./ComponentsProject";
import { Collapse, FormControlLabel, Switch } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { Tooltip, Typography } from "@material-ui/core";
import { BlueSkySwitch } from "./GestionPermissions";

export default function ProjectPermissions({ data, handleChange, state }) {
  const { permissionsRole } = useContext(AuthContext);

  return (
    <>
      <div className="wrapped">
        <Tooltip title={data.label}> 
          <div className="column_positions">
            <Typography variant="inherit" noWrap>
              {data.label} 
            </Typography>

            <Switch
              onClick={() =>
                handleChange(data.name)
              } /* Switches padres de medidores y área */
              checked={permissionsRole[data.name]}
            ></Switch>
          </div>
        </Tooltip>
        {state[data.name] &&
          data.action?.map((m) => (
            <div className="container_projects">
              <ComponentsProject
                data={m}
                handleChange={handleChange}
                state={state}
              />
            </div>
          ))}
        {state[data.name] &&
          data.component?.map((j) => (
            <div className="container_projects">
              <ComponentsProject
                data={j}
                handleChange={handleChange}
                state={state}
              />
            </div>
          ))}
      </div>
    </>
  );
}
