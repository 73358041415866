import React, { useState, useContext } from "react";

//Material UI components
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

//Material UI components & Styles
import { createTheme, ThemeProvider } from "@mui/material/styles";

//components
import Loading from "../../../../components/loading/Loading";

//Queries
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_CONTROLLERS,
  VIRTUAL_CONTROLLER_CREATE_ROOM,
} from "../../../../controllers/roomControllers";

//multilanguage
import { useTranslation } from "react-i18next";

const VirtualRoomModal = (props) => {
  const { open, setOpenVirtualRoomModal, projectId, refetchArea } = props;
  const { t } = useTranslation();
  const [createRoom] = useMutation(VIRTUAL_CONTROLLER_CREATE_ROOM);
  const { data, loading, refetch } = useQuery(GET_ALL_CONTROLLERS, {
    variables: {
      projectId: projectId,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  const customGreen = "#acf6ac";
  const theme = createTheme({
    palette: {
      success: {
        main: customGreen,
      },
    },
  });

  const [areaName, setAreaName] = useState("");
  const [virtualControllerId, setVirtualControllerId] = useState("");

  const handleCloseVirtualRoomModal = () => {
    setOpenVirtualRoomModal(false);
  };

  const handleInputAreaName = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-ZáéíóúñÁÉÍÓÚÑ0-9 ]+$/;

    if (regex.test(value) || value === "") {
      setAreaName(value);
    } else {
    }
  };

  const handleVirtualControllerInput = (code) => {
    setVirtualControllerId(code);
  };

  async function createRoomWithVirtualController() {
    if (areaName && virtualControllerId) {
      try {
        createRoom({
          variables: {
            data: [
              {
                controllerId: virtualControllerId,
                name: areaName,
                projectId: projectId,
              },
            ],
          },
        });
        setTimeout(() => {
          refetchArea();
          handleCloseVirtualRoomModal();
        }, 2000);
      } catch (err) {
        console.error("Excepción:", err);
      }
    } else {
    }
  }

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        PaperProps={{
          sx: {
            display: "flex",
            borderRadius: "8px",
            width: "35%",
            height: "auto",
            [theme.breakpoints.down("md")]: {
              width: "45%",
            },
            [theme.breakpoints.down("sm")]: {
              width: "90%",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#2EB142",
            color: "#FFFFFF",
            lineHeight: "2.6",
            padding: "5px 5px 5px 40px",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "auto",
              fontWeight: "bold",
            }}
          >
            {t(
              "VIEW_AREAS.COMPONENT_VIRTUAL_CONTROLLER_CREATION_ROOM_MODAL.TITLE"
            )}
          </span>
          <IconButton onClick={handleCloseVirtualRoomModal}>
            <CloseIcon sx={{ color: "#ffff" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            padding: "20px 24px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              gap: "25px",
              width: "90%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "18px",
              marginTop: "20px",
            }}
          >
            <TextField
              id="outlined-basic"
              label={t(
                "VIEW_AREAS.COMPONENT_VIRTUAL_CONTROLLER_CREATION_ROOM_MODAL.AREA_NAME"
              )}
              variant="outlined"
              size="small"
              InputProps={{
                sx: { borderRadius: "8px", width: "100%" },
              }}
              onChange={handleInputAreaName}
              value={areaName}
              required={true}
              color={areaName ? "primary" : "error"}
            />

            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-simple-select-label"
                color={virtualControllerId ? "primary" : "error"}
              >
                {t(
                  "VIEW_AREAS.COMPONENT_VIRTUAL_CONTROLLER_CREATION_ROOM_MODAL.CONTROLLER"
                )}
              </InputLabel>
              <Select
                sx={{ borderRadius: "8px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={virtualControllerId}
                label={t(
                  "VIEW_AREAS.COMPONENT_VIRTUAL_CONTROLLER_CREATION_ROOM_MODAL.CONTROLLER"
                )}
                onChange={(e) => handleVirtualControllerInput(e.target.value)}
                color={virtualControllerId ? "primary" : "error"}
              >
                {data?.getAllControllers
                  ?.filter(
                    (controller) =>
                      controller.typeGateway === "virtual" ||
                      controller.typeGateway === "horus_700")
                  ?.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <ThemeProvider theme={theme}>
            <Button
              sx={{
                width: "90%",
                padding: "7px",
                color: "#000",
                borderRadius: "8px",
                lineHeight: "18px",
              }}
              variant="contained"
              color="success"
              onClick={() => createRoomWithVirtualController()}
              disabled={areaName && virtualControllerId ? false : true}
            >
              {t(
                "VIEW_AREAS.COMPONENT_VIRTUAL_CONTROLLER_CREATION_ROOM_MODAL.ACCEPT_BUTTON"
              )}
            </Button>
          </ThemeProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VirtualRoomModal;
