import React, { useState, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

//multilanguage
import { useTranslation } from "react-i18next";

function OccupancyChart({ data, dateMin, dateMax, timeZone }) {
  const { t } = useTranslation();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  return (
    <>
      {!data?.graph_data ? (
        <div className="date_vacio_report_measurer">
          <span>
            <i class="fas fa-exclamation-triangle"></i>{" "}
            {t(
              "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.NO_DATA"
            )}
          </span>
        </div>
      ) : (
        <HighchartsReact
          options={{
            title: {
              text: "",
              align: "left",
            },
            yAxis: {
              min: 0,
              max: data?.graph_data.length,
              tickAmount: data?.graph_data.length + 1,
              title: {
                enabled: false,
              },
            },
            plotOptions: {
              area: {
                fillOpacity: 0.4,
                marker: {
                  enabled: true,
                  symbol: "circle",
                },
              },
              series: {
                states: {
                  inactive: {
                    enabled: false,
                  },
                },
                turboThreshold: 0,
                marker: {
                  enabled: true,
                  symbol: "circle",
                },
                label: {
                  enabled: false,
                },
              },
            },
            legend: {
              align: innerWidth <= 980 ? "center" : "right",
              verticalAlign: innerWidth <= 980 ? "bottom" : "top",
              layout: innerWidth <= 980 ? "horizontal" : "vertical",
              x: 0,
              y: 0,
            },
            subtitle: {
              text: "",
              align: "left",
            },
            chart: {
              zoomType: "x",
            },
            xAxis: {
              type: "datetime",
              tickInterval: 3600000,
              min: dateMin,
              max: dateMax,
              labels: {
                format: "{value:%H:%M}",
              },
            },
            tooltip: {
              xDateFormat: "%H:%M:%S",
              headerFormat: "<b>{point.key}</b><br>",
            },
            time: {
              timezoneOffset: timeZone,
            },
            series: data?.graph_data,
            credits: {
              enabled: false,
            },
          }}
        />
      )}
    </>
  );
}

export default OccupancyChart;
