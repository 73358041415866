import HomeIcon from "@mui/icons-material/Home";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";

const Summary = () => {
  const installedMeters = localStorage.getItem("editedMeterVariables")
    ? JSON.parse(localStorage.getItem("editedMeterVariables"))
    : localStorage.getItem("editedMeterVariables");

  return (
    <div className="summary">
      <span className="summary_title">Resumen</span>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <span>
          <HomeIcon sx={{ height: "30px", width: "30px" }} />
        </span>
        {localStorage.getItem("inputSelection")
          ? localStorage.getItem("inputSelection")
          : null}
      </div>
      {localStorage.getItem("showMeters") === "true"
        ? installedMeters?.map((meter, index) => (
            <>
              <div className="summary_item_container" key={index}>
                <div className="items_labels">
                  <span>
                    <ElectricMeterIcon sx={{ height: "30px", width: "30px" }} />
                  </span>
                  <b className="serial_label">SN:</b> {meter?.serial}
                </div>
                <div className="secondary_labels">
                  {<b>Alias </b>}
                  {": "}
                  {meter?.alias}
                </div>
                <div className="secondary_labels">
                  {<b>Fact. Potencia</b>} {": "}
                  {meter?.powerFactor}
                </div>
                <div className="secondary_labels">
                  {<b>Relación Tc</b>} {": "}
                  {meter?.relationTc}
                </div>
                <div className="secondary_labels">
                  {<b>Max. Corriente</b>}
                  {": "}
                  {meter?.maximumCurrent}
                </div>
              </div>
            </>
          ))
        : null}
    </div>
  );
};

export default Summary;
