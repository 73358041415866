import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../context/AuthProvider";

//components
import Area from "../../../components/areas/Area";
import ActionsMenuButton from "./components/ActionsMenuButton";
import EditRoomModal from "./components/EditRoomModal";
import GeneralOccupation from "../../../components/highcharts/GeneralOccupation";
import Loading from "../../../components/loading/Loading";
import ProjectCardAreaControl from "../../../components/projects/ProjectCardAreaControl";
import ReportFailsByProject from "../../../components/reports/ReportFailsByProject";
import VirtualRoomModal from "./components/VirtualRoomModal";

//helpers
import { nameFilterAreas } from "../../../utils/helpers";
import { useGetDataMacrometersVariables } from "../../../components/projects/helperMacroMetters/getDataMacrometersVariables";
import { useGetDataMetters } from "../../../components/projects/helperMacroMetters/getDataMetters";

//query and mutation
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_ROOMS_BY_PROJECT } from "../../../controllers/roomControllers";
import { PDF_GENERAL_OCUPATION_REPORT } from "../../../controllers/pdfControllers";

//material UI
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";

//multilanguage
import { useTranslation } from "react-i18next";

const Areas = (props) => {
  const { t } = useTranslation();
  const [pdfGenerator] = useMutation(PDF_GENERAL_OCUPATION_REPORT);
  const {
    areas,
    setAreas,
    setOpenModal,
    setStateBathroom,
    stateBathroom,
    permissionsRoleUser,
    setCalendarDatePicker,
    macrometer,
    setDataMacrometer,
    dataMacrometers,
    setDataMacrometerVariables,
    dataMacrometerVariables,
  } = useContext(AuthContext);
  const statePlugin = areas.map((i) =>
    i.Device.find(
      (i) =>
        i?.visible &&
        i?.Category?.category === 600 &&
        i?.Category?.sub_category === 3
    )
  )[0];
  const initialStates = {
    all: true,
    on_line: false,
    off_line: false,
    any_status: false,
    busy: false,
  };

  // const [ countries, setCountries ] = useState([])
  const [open, setOpen] = React.useState(false);
  const [openVirtualRoomModal, setOpenVirtualRoomModal] = React.useState(false);
  const [openEditRoomModal, setEditRoomModal] = React.useState(false);
  const [openDropFilter, setOpenDropFilter] = useState(false);
  const [openDropReport, setOpenDropReport] = useState(false);
  const [openReportFailsByProject, setOpenReportFailsByProject] =
    useState(false);
  const project__id = Number(localStorage.getItem("id"));

  const history = useHistory();

  const [filter_cat, setFilterCat] = React.useState(initialStates);

  const handleAreasSave = () => {
    localStorage.removeItem("areas"); // Limpiamos el storage
    const filterArea = [];
    const area = areas[0];
    const isPluginMotel =
      area?.Device?.find(
        (j) => j?.Category?.category === 600 && j?.Category?.sub_category === 3
      )?.variables?.Estado !== undefined;
    if (isPluginMotel) {
      // Cuando se el caso de plugin motel
      areas?.map((i) => {
        return filterArea.push({
          status: i?.Device?.find(
            (j) =>
              j?.Category?.category === 600 && j?.Category?.sub_category === 3
          )?.variables?.Estado,
          name: i.alias,
          id: i?.id,
          realname: i?.name,
        });
      });
    } else {
      // Cuando se el caso de plugin noraml y classroom
      areas?.map((i) => {
        return filterArea.push({
          name: i?.alias,
          id: i?.id,
          newRoomId: i?.NewRoomId,
          status: i?.status,
          realname: i?.name,
          typeGateway: i?.Controller?.typeGateway,
        });
      });
    }
    localStorage.setItem("areas", JSON.stringify(filterArea));
  };

  const handleClickOpen = () => {
    setOpenModal(true);
    setOpenDropReport(true);
  };

  const closeDrops = () => {
    setOpenDropFilter(false);
    setOpenDropReport(false);
  };

  const [inputValues, setInputValues] = useState({
    search_areas: "",
  });

  const { data, loading, error, refetch } = useQuery(GET_ALL_ROOMS_BY_PROJECT, {
    variables: { ProjectId: project__id, id: project__id },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: false,
  });


  const cityProject = data?.getProjectByIdHeader?.city;
  const datosMacrometers = useGetDataMetters();
 
  const seriales = datosMacrometers?.map((i) => i.serial);
  const {
    data: dataquery,
    loading: loadingquery,
    error: errorquery,
  } = useGetDataMacrometersVariables(seriales, cityProject);

    useEffect(() => {
      if(datosMacrometers) {
        const medidoresArgos = datosMacrometers?.map((i) => (i.modelo))
        if (medidoresArgos[0] === 'DTS-353/CT/V3') {
          sessionStorage.setItem("macrometerArgos", JSON.stringify(medidoresArgos));
        }
      }
    },[data])

  useEffect(() => {
    if (datosMacrometers) {
      
      let resultado = {};
      datosMacrometers?.forEach((obj) => {
        let serial = obj?.serial;
        if (!resultado[serial]) {
          resultado[serial] = {
            serial: serial,
            alias: obj.alias,
            model: obj.modelo,
          };
        }
      });
      
      dataquery?.getMeterHistoryVariables?.forEach((obj) => {
        let serial = obj?.SerialMedidor;
        if (resultado[serial]) {
          resultado[serial].variable = obj?.variable;
        }
      });
      let resultadoFinal = Object.values(resultado);
      setDataMacrometerVariables(resultadoFinal);
      sessionStorage.setItem("resultadoFinal", JSON.stringify(resultadoFinal));
    } 
  }, [dataquery]);

  const listMacromeasureOption =
    data?.getMettersInProjets[0]?.Metters.length > 0 ||
    data?.getInfoMetersByProject.length > 0;

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  useEffect(() => {
    areas
      .find((i) => i.id === 2148)
      ?.Device.filter((i) => i.id === 26128 || i.id === 26127 || i.id === 26126)
      .map((k) => {
        return setStateBathroom({
          ...stateBathroom,
          [k.id]: k.variables.Status,
        });
      });
  }, []);

  useEffect(() => {
    if (data) {
      setAreas(data.getAllRoomsByProject);
      localStorage.setItem("id", data.getAllProjects[0].id);
      localStorage.setItem("imgProject", data.getAllProjects[0].img);
      localStorage.setItem("nameProject", data.getAllProjects[0].name);
      localStorage.setItem("startTime", data.getAllProjects[0].start_time);
      localStorage.setItem("endtTime", data.getAllProjects[0].end_time);
      localStorage.setItem("prefix", data.getAllProjects[0].prefix_currency);
      localStorage.setItem("kwhCost", data.getAllProjects[0].kwh_cost);
    }
  }, [data]);

  const filter_category = () => {
    if (filter_cat.on_line) {
      return areas.filter((i) => i.status == "Libre");
    } else if (filter_cat.busy) {
      return areas.filter((i) => i.status == "Ocupado");
    } else if (filter_cat.any_status) {
      return areas.filter((i) => i.status == null);
    } else if (filter_cat.off_line) {
      return areas.filter((i) => i.Controller.online === false);
    } else {
      return areas;
    }
  };

  const count_by_status = () => {
    return {
      all: areas.length,
      busy: areas.filter((i) => i.status == "Ocupado").length,
      free: areas.filter((i) => i.status == "Libre").length,
      any_state: areas.filter((i) => i.status == null).length,
      offline: areas.filter((i) => i.Controller.online === false).length,
    };
  };

  const _areas = () => {
    if (inputValues.search_areas === "") {
      return filter_category();
    } else {
      let __areas__ = [...areas];
      return __areas__.filter((key) =>
        key.name.toLowerCase().includes(inputValues.search_areas)
      );
    }
  };
  const printRef = React.useRef();

  const openMeasurer = () => {
    history.push(`/app/measurers/${data.getProjectByIdHeader.id}`);
  };

  const handleReportFailsByProject = () => {
    setOpenReportFailsByProject(!openReportFailsByProject);
  };

  const handleChangeCat = (__key__) => {
    if (__key__ == "on_line") {
      setFilterCat({
        on_line: true,
        off_line: false,
        any_status: false,
        busy: false,
        all: false,
      });
    } else if (__key__ == "any_status") {
      setFilterCat({
        on_line: false,
        off_line: false,
        any_status: true,
        busy: false,
        all: false,
      });
    } else if (__key__ == "off_line") {
      setFilterCat({
        on_line: false,
        any_status: false,
        off_line: true,
        busy: false,
        all: false,
      });
    } else if (__key__ == "busy") {
      setFilterCat({
        on_line: false,
        off_line: false,
        any_status: false,
        all: false,
        busy: true,
      });
    } else {
      setFilterCat({
        on_line: false,
        off_line: false,
        any_status: false,
        all: true,
        busy: false,
      });
    }
    setOpenDropFilter(false);
  };

  //const pdfExportComponent = React.useRef(null);

  // const handleOpenVirtualRoomModal = () => {
  //   setOpenVirtualRoomModal(true);
  // };

  if (error) {
    return (
      <div>
        <lottie-player
          style={{
            width: "70%",
            marginLeft: "30%",
            marginRight: "auto",
          }}
          className="_logo"
          src="https://assets1.lottiefiles.com/private_files/lf30_tonsVH.json"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="_areas_container">
      <div className="__areas_container__">
        <div className="__areas_container_logo__">
          <img
            src={data?.getProjectByIdHeader?.img}
            className="__areas__img"
            alt="Logo"
            width="180"
            height="180"
          />
        </div>
        <div className="__areas_container_project_and_filters__">
          <div className="__areas_container_project__">
            <ProjectCardAreaControl data={data?.getProjectByIdHeader} />
          </div>
          <div className="__areas_container_filters__">
            <div className="__areas_container_name_filters_and_search__">
              <div className="__area_container_filters_name__">
                {t("VIEW_AREAS.COMPONENT_HEADER_AREAS.AREAS")}
              </div>

              {Boolean(statePlugin) ? (
                <div></div>
              ) : (
                <div
                  className="__area_container_filters_status__"
                  onClick={() => setOpenDropFilter(!openDropFilter)}
                >
                  <span className={`__area_container_text_filter__`}>
                    {nameFilterAreas(filter_cat, count_by_status(), t)}
                  </span>
                  <span className="__area_container_icon_filter__">
                    <i className="fas fa-sort"></i>
                  </span>
                </div>
              )}

              <div
                className={`${
                  openDropFilter
                    ? "__open_drop_filter_open__"
                    : "__open_drop_filter_close__"
                }`}
              >
                <span className="__filters_names__">
                  <div
                    className="__filter_name_todas__"
                    onClick={() => handleChangeCat("all")}
                  >
                    {t(
                      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_STATUS_FILTER.ALL"
                    )}
                    ({count_by_status().all})
                  </div>
                  <div
                    className="__filter_name_libre__"
                    onClick={() => handleChangeCat("on_line")}
                  >
                    {t(
                      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_STATUS_FILTER.AVAILABLE"
                    )}
                    ({count_by_status().free})
                  </div>
                  <div
                    className="__filter_name_ocupado__"
                    onClick={() => handleChangeCat("busy")}
                  >
                    {t(
                      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_STATUS_FILTER.BUSY"
                    )}
                    ({count_by_status().busy})
                  </div>
                  <div
                    className="__filter_name_fuera__"
                    onClick={() => handleChangeCat("off_line")}
                  >
                    {t(
                      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_STATUS_FILTER.OFFLINE"
                    )}
                    ({count_by_status().offline})
                  </div>
                  <div
                    className="__filter_name_sin__"
                    onClick={() => handleChangeCat("any_status")}
                  >
                    {t(
                      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_STATUS_FILTER.UNKNOW_STATUS"
                    )}
                    ({count_by_status().any_state})
                  </div>
                </span>
              </div>

              <div className="__area_container_filters_search__">
                <span className="_areas_container_search_input">
                  <input
                    type="text"
                    className=""
                    placeholder={t(
                      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_SEARCH_INPUT.SEARCH"
                    )}
                    name="search_areas"
                    value={inputValues.search_areas}
                    onChange={handleOnChange}
                  />
                </span>
              </div>
              {permissionsRoleUser?.permissionsRole?.OptionAreas ? (
                <ActionsMenuButton
                  setOpenVirtualRoomModal={setOpenVirtualRoomModal}
                  setEditRoomModal={setEditRoomModal}
                />
              ) : null}
            </div>
            {permissionsRoleUser?.permissionsRole?.AreaRoaVer ? (
              <div className="__areas_container_filter_report__">
                <div className="_areas_report">
                  <span className="_report_ver_container">
                    <button
                      type="text"
                      className="_report_ver"
                      onClick={() => setOpenDropReport(!openDropReport)}
                    >
                      <span>
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.REPORTS"
                        )}
                      </span>
                    </button>
                    <span
                      className="__area_container_icon_report__"
                      onClick={() => setOpenDropReport(!openDropReport)}
                    >
                      <i className="fas fa-sort"></i>
                    </span>
                  </span>
                </div>

                <div
                  className={`${
                    openDropReport
                      ? "__open_drop_report_open__"
                      : "__open_drop_report_close__"
                  }`}
                >
                  <span className="__buttons_names__">
                    {permissionsRoleUser?.permissionsRole?.AreaRpog ? (
                      <div
                        className="__button_ocupation__"
                        onClick={() => {
                          handleClickOpen();
                        }}
                      >
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCUPATION"
                        )}
                      </div>
                    ) : null}

                    {/* {permissionsRoleUser?.permissionsRole?.AreaRpog && listMacromeasureOption ? (
                      <div
                        className="__button_medidores__"
                        onClick={openMeasurer}
                      >
                        {t('VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.MEASURERS')}
                      </div>
                    ) : null} */}
                    {permissionsRoleUser?.permissionsRole?.AreaRpdf ? (
                      <div
                        className="__button_report_fails_by_project__"
                        onClick={handleReportFailsByProject}
                      >
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORTS"
                        )}
                      </div>
                    ) : null}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {permissionsRoleUser?.permissionsRole?.Area ? (
        <div className="_areas_container_location" onClick={closeDrops}>
          {handleAreasSave(_areas())}
          {_areas()
            .filter((i) => i.visible)
            .map((item, key) => (
              <Area
                key={key}
                data={item}
                project={data?.getProjectByIdHeader}
                dataMetter={data?.getMettersInProjets}
              />
            ))}
        </div>
      ) : null}
      <GeneralOccupation projectData={data?.getProjectByIdHeader} />
      <ReportFailsByProject
        openReportFailsByProject={openReportFailsByProject}
        handleReportFailsByProject={handleReportFailsByProject}
        projectData={data?.getProjectByIdHeader}
      />
      <VirtualRoomModal
        open={openVirtualRoomModal}
        setOpenVirtualRoomModal={setOpenVirtualRoomModal}
        projectId={project__id}
        refetchArea={refetch}
      />
      <EditRoomModal
        areas={data?.getAllRoomsByProject}
        projectId={project__id}
        openEditRoomModal={openEditRoomModal}
        setEditRoomModal={setEditRoomModal}
        refetchArea={refetch}
      />
    </div>
  );
};

export default Areas;
