import React, { useContext } from "react";

//Material UI
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
//Multilanguage
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../context/AuthProvider";

const ActionsMenuButton = (props) => {
  const { setOpenVirtualRoomModal, setEditRoomModal } = props;
  const {permissionsRoleUser} = useContext(AuthContext);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlerPickAction = (action) => {
    setAnchorEl(null);
    if (action === 1) {
      setOpenVirtualRoomModal(true);
    } else if (action === 2) {
      setEditRoomModal(true);
    }
  };

  return (
    <div className="add_area_button">
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          border: "1px solid",
          borderColor: "white",
          boxShadow: "1px 1px 4px 4px rgba(146, 158, 158, 0.3)",
        }}
        onClick={handleClick}
      >
        <MoreHorizIcon color="action" />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handlerPickAction}
        TransitionComponent={Fade}
      >
        {permissionsRoleUser?.permissionsRole?.AreaCreate ? (
        <MenuItem onClick={() => handlerPickAction(1)}>
         {t(
             "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_AREA_ACTIONS_MENU_BUTTON.CREATE_AREA"
           )}
        </MenuItem>
         ):null}
        {permissionsRoleUser?.permissionsRole?.AreaEdit ? (
          <MenuItem onClick={() => handlerPickAction(2)}>
          {t(
             "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_AREA_ACTIONS_MENU_BUTTON.EDIT_AREA"
             )}
          </MenuItem>
        ): null}
       
      </Menu>
    </div>
  );
};

export default ActionsMenuButton;
