import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLazyQuery,  useMutation, useQuery } from "@apollo/client";
import { secondClient } from "../../index";
// import { EXCEL_GENERAL_OCUPATION_REPORT } from "../../../controllers/pdfControllers";
import { EXCEL_MACROMETER_REPORT } from "../../controllers/pdfControllers";
// import Loading from "../../../components/loading/Loading";
import Loading_devices from "../loading/Loading_devices";
import { AuthContext } from "../../context/AuthProvider";




export const ModalExcelMacrometer = ({open, serial, timeZone, setOpenModalExcel}) => {
    const [dateInitial, setDateInitial] = useState()
    const [dateFinal, setDateFinal] = useState()
    const [baseXLSX, setBaseXLSX] =useState()
    const [loadingDate, setLoadingDate] = useState(false)
    const { rangeDatePicker} = useContext(AuthContext);
    
    const nameProject = localStorage.getItem('nameProject')
    const today = localStorage.getItem('localTime')

   const handlerClose = () => {
    setOpenModalExcel(false)
   }

   const handlerOpenLoading = () => {
    // setLoading(true)
   }
   const handlerCloseLoading = () => {
    // setLoading(false)
    handlerClose()
    setDateInitial()
    setDateFinal()
    setBaseXLSX()
   
   }

    const handlerInitialDate = (e) => {
        const date= e.target.value
        setDateInitial(date)
    }
    const handlerFinalDate = (e) => {
        const finDate = e.target.value
        setDateFinal(finDate)
    }

    const handleClick = (e) => {
      e.preventDefault();
      e.currentTarget.querySelector("input").showPicker(); // Abrir el calendario
    };


        const [getXlsx, {data, loading,error}] = useLazyQuery(EXCEL_MACROMETER_REPORT,   {client: secondClient},); 
        const dateXlsx = () => { 
          const dates = testRange(dateInitial, dateFinal);

          try{
           getXlsx({
                variables:{
                    serial:serial,
                    startTime:new Date(dates.startDate),
                    endTime:new Date(dates.endDate)
                }
            },setLoadingDate(true))
            .then((resul)=>  {
                    setBaseXLSX(resul.data?.getConsumptionReportFile?.File?.fileBase64)
                    if(resul) {
                      decodeXLSX(resul.data?.getConsumptionReportFile?.File?.fileBase64, dates.startDate, dates.endDate)
                    }
                    setLoadingDate(false)
              })
              .catch((err) => {
                console.log(err);
              });
          } catch (err) {
            console.log(err);
          }
          }
    
    // const dateXlsx = ()  => {
    //     try {
    //          Mutation({
    //             variables: {
    //                 projectId: Number(projectID),
    //                 startTime: new Date(dateInitial).getTime(),
    //                 endTime: new Date(dateFinal).getTime(),
    //                 dateRange: testRange(),
    //                 timeZone: String(timeZone),
    //             }
    //         },setLoading(true))
            
    //         .then((resul)=>  {
    //                 setBaseXLSX(resul.data?.generateExcelReport?.excelFile)
    //                 if(resul) {
    //                   decodeXLSX(resul.data?.generateExcelReport?.excelFile)
    //                 }
    //                 setLoading(false)
    //           })
    //           .catch((err) => {
    //             console.log(err);
    //           });
    //       } catch (err) {
    //         console.log(err);
    //       }
    // }

    const testRange =(dateInitial, dateFinal) => {
      const dateRangeOne = new Date(dateInitial).getTime()
      const dateRangeTwo = new Date(dateFinal).getTime()
        if(dateRangeTwo > dateRangeOne) {
          return {startDate : dateInitial, endDate:dateFinal}
        } else {
          return {startDate:dateFinal, endDate: dateInitial}
        }
        }

    const decodeXLSX = (item, startDate, endDate) => {
     
          const linkSource = `data:application/xlsx;base64,${item}`
          const downloadLink = document.createElement("a");
          const fileName = `Informe general de medición ${nameProject} ${startDate} - ${endDate}.xlsx`;
           downloadLink.href = linkSource;
           downloadLink.download = fileName;
           downloadLink.click();
           handlerCloseLoading()
   
    }

    const currentDate = new Date().toISOString().split("T")[0]
    const handleValidationAccions = () => {
      if (dateInitial === undefined) return true
      if (dateFinal === undefined) return true
      if (dateInitial === '') return true
      if(dateFinal === '') return true
    }

  return (
    <Dialog 
    sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          maxHeight: 500,
          borderRadius: "8px",
        },
      }}
      maxWidth="xs"
      open={open}>
         <DialogTitle
        sx={{
          background: "#3f9948",
          color: "white",
          fontFamily: "Montserrat, sans-serif",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "16px", 
          fontSize:'16px'
        }}
      >
       
        <b style={{marginLeft:'20px'}}>Ingresa los datos requeridos a descargar</b>
          {!loadingDate ? 
          <Button sx={{ color: "white", marginRight: "-14px", }} onClick={handlerClose}> X</Button> : null
          }
   
      </DialogTitle>
      {!loadingDate ? 
      <>
        <DialogContent>
        <p
          style={{
            fontFamily: "Montserrat, sans serif",
            textAlign: "justify",
            color: "black",
          }}
        >esta es una fase beta para descarga de informes XLSX</p>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <TextField
            onChange={handlerInitialDate}
            name="initialDate"
            fullWidth
            type="date"
            size="small"
            InputProps={{
              sx: { borderRadius: "8px" }
            }}
            label="Fecha inicial"
            InputLabelProps={{
            shrink: true, 
            }}
            inputProps={{ max: currentDate,}}
            onClick={(e) => handleClick(e)}
          />
          <TextField
            onChange={handlerFinalDate}
            disabled={dateInitial === undefined || dateInitial === ''}
            name="finalDate"
            fullWidth
            label="Fecha final"
            InputLabelProps={{
            shrink: true, 
            }}
            type="date"
            size="small"
            InputProps={{ sx: { borderRadius: "8px" } }}
            inputProps={{ max: currentDate,}}
            onClick={(e) => handleClick(e)}
          />
        </div>
     
        </DialogContent> 
           <DialogActions
           sx={{
             display: "flex",
             justifyContent: "center",
             padding: "0px 24px 20px",
           }}
         >
    
           <Button
             fullWidth
             sx={{
               borderRadius: "8px",
               marginBottom: "5px",
               backgroundColor: "#2EB142",
               color: "white",
               fontFamily: "Montserrat, sans serif ",
               "&:hover": {
                 backgroundColor: "#336535",
               },
             }}
                 onClick={dateXlsx}
             disabled={handleValidationAccions()}
           >
             <b>Imprimir Informe</b>
           </Button> 
         </DialogActions>
         </>
        :
        <DialogContent>
      <p
        style={{
          fontFamily: "Montserrat, sans serif",
          textAlign: "justify",
          color: "black",
        }}
      >Se esta generando el informe podria tardar unos segundos dependiendo la cantidad de datos </p>
       {loadingDate&& 
       <div style={{ display: 'grid', justifySelf: 'center', alignSelf: 'center', marginLeft: '45px', height: '100px',  width: '100px'}}>
       <Loading_devices  />

      </div> }
      </DialogContent>
     
      }   
        
        
        </Dialog>
  )
}
