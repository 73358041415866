import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { Validations } from "../../userManagement/editUsers/chagesPassword/style/changesPasswordForm.style";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@material-ui/core/Snackbar";
//multilanguage
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { RESET_PASSWORD } from "../../../controllers/authenticationController";
import { encryptData } from "../../../utils/encryptDecrypt";
// display password
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

export const ModalChangePasswordRequired = () => {
  const password = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY;
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [changePassword] = useMutation(RESET_PASSWORD);
  const [openAlert, setOpenAlert] = useState(false);
  const [textPassword, setTextPassword] = useState(false);
  const [textPasswordTwo, setTextPasswordTwo] = useState(false);

  const [newPassword, setNewPassword] = useState({
    pass: "",
    newPass: "",
  });
  const [validations, setValidations] = useState({
    length: false,
    uppercase: false,
    number: false,
    specialChar: false,
    equalPasswords: false,
  });
  const validatePassword = (values) => {
    return {
      length: values.pass.length >= 8,
      uppercase: /[A-Z]/.test(values.pass),
      number: /[0-9]/.test(values.pass),
      specialChar: /[!@#$%^&*()_\-+=\[{\]};:'",<.>/?\\|~`]/.test(values.pass),
      equalPasswords: values.pass === values.newPass,
    };
  };
  
  const validationsOptions = [
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM2"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM3"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM4"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM5"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM6"),
  ];


  useEffect(() => {
    const storedValue = localStorage.getItem("SecurityPassword");

    if (JSON.parse(storedValue) === false ) {
      setOpen(true);
    }
  }, []);

  const togglePasswordVisibility = () => {
    setTextPassword(!textPassword);
  };

  const togglePasswordVisibilityTwo = () => {
    setTextPasswordTwo(!textPasswordTwo)
  }

  function passwordRequirements(objeto) {
    for (const propiedad in objeto) {
      if (objeto.hasOwnProperty(propiedad) && objeto[propiedad] !== true) {
        return false;
      }
    }
    return true;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    openAlert(false);
  };
  const refreshState = () => {
    setTimeout(() => {
      setValidations({
        length: false,
        uppercase: false,
        number: false,
        specialChar: false,
        equalPasswords: false,
      });

      setNewPassword({
        pass: "",
        newPass: "",
      });
      localStorage.clear();
      window.location.reload();
      history.push("/");
      setOpen(false);
    }, "5000");
  };
  const colorSpan = (position) => {
    const error = "#EDBABA";
    const success = "#B2E5B7";
    const normal = "#847F7F";
    if (newPassword?.pass !== undefined) {
      switch (position) {
        case 0:
          return validations.length ? success : error;
        case 1:
          return validations.uppercase ? success : error;
        case 2:
          return validations.number ? success : error;
        case 3:
          return validations.specialChar ? success : error;
        case 4:
          return validations.equalPasswords ? success : error;
      }
    } else {
      return normal;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedValues = { ...newPassword, [name]: value };
    const updatedValidations = validatePassword(updatedValues);
    setNewPassword(updatedValues);
    setValidations(updatedValidations);
  };

  const validationColor = () => {
    if (passwordRequirements(validations) === false) {
      return "error";
    } else {
      return "success";
    }
  };

  async function userEdit(e) {
    const newPass = await encryptData(newPassword.pass, password);
    const newPassConfirm = await encryptData(newPassword.newPass, password);
    if (passwordRequirements(validations)) {
      try {
        changePassword({
          variables: {
            token: localStorage.getItem("token"),
            password: newPass,
            confirmPassword: newPassConfirm,
          },
        })
          .then((response) => {
            setOpenAlert(true);
            refreshState();
          })
          .catch((err) => {});
      } catch (err) {}
    }
  }

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          maxHeight: 500,
          borderRadius: "8px",
        },
      }}
      maxWidth="sm"
      open={open}
    >
      <DialogTitle
        sx={{
          background: "#3f9948",
          height: "auto",
          color: "white",
          textAlign: "center",
          fontFamily: "Montserrat, sans serif",
        }}
      >
        <b>{t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.TITLE")}</b>
      </DialogTitle>
      <DialogContent>
        <p
          style={{
            fontFamily: "Montserrat, sans serif",
            textAlign: "justify",
            color: "black",
          }}
        >
          <p></p>
          {t(
            "VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.MODAL_UPDATE_DESCRIPTION"
          )}
        </p>
        <b>
          {" "}
          {t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.DESCRIPTION_BOLD")}
        </b>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
              justifyC11ontent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "3px" }}
            >
              {validationsOptions?.map((item, key) => (
                <Validations background={colorSpan(key)}>
                  <div>
                    <span>•</span>
                    {item}
                  </div>
                </Validations>
              ))}
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <TextField
                // helperText={}
                onChange={handleChange}
                name="pass"
                color={validationColor()}
                fullWidth
                label={t(
                  "VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.NEW_PASSWORD"
                )}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {textPassword ? (
                        <VisibilityIcon
                          sx={{
                            "&:hover": { color: "#494949", cursor: "pointer" },
                          }}
                          onClick={togglePasswordVisibility}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={togglePasswordVisibility}
                          sx={{
                            "&:hover": { color: "#494949", cursor: "pointer" },
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                  sx: { borderRadius: "8px" }, // Estilos adicionales
                }}
                type={textPassword ? "text" : "password"}
                size="small"
              />
              <TextField
                // helperText={}
                onChange={handleChange}
                name="newPass"
                color={validationColor()}
                fullWidth
                label={t(
                  "VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.CONFIRM_PASSWORD"
                )}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {textPasswordTwo ? (
                        <VisibilityIcon
                          sx={{
                            "&:hover": { color: "#494949", cursor: "pointer" },
                          }}
                          onClick={togglePasswordVisibilityTwo}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={togglePasswordVisibilityTwo}
                          sx={{
                            "&:hover": { color: "#494949", cursor: "pointer" },
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                  sx: { borderRadius: "8px" }, // Estilos adicionales
                }}
                type={textPasswordTwo ? "text" : "password"}
                size="small"
              />
            </div>
          </div>
          <Button
            fullWidth
            disabled={!passwordRequirements(validations)}
            onClick={(e) => {
              userEdit(e);
            }}
            sx={{
              borderRadius: "8px",
              height: "35px",
              marginTop: "20px",
              marginBottom: "5px",
              backgroundColor: "#3f9948",
              color: "white",
              fontFamily: "Montserrat, sans serif ",
              "&:hover": {
                backgroundColor: "#336535",
              },
              fontSize: "12px",
            }}
          >
            <b>
              {t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.CHANGE_PASSWORD")}
            </b>
          </Button>
        </div>
      </DialogContent>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="success">
          {t(
            "VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.SUCCESSFUL_CHANGE_REQUIRED"
          )}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
ModalChangePasswordRequired.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};
