import { useEffect, useState, useContext, Fragment } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { Fab } from "@material-ui/core";
import { ModalRole } from "./ModalRole";
import { ModalAlertChangesRoles } from "./ModalAlertChangesRoles";
import { Tooltip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import RolesToggleSwitch from "../../../src/components/toggleSwitch/RolesToggleSwitch";
import HowToRegIcon from "@mui/icons-material/HowToReg";

//multilanguage
import { useTranslation } from "react-i18next";

export const TableRoles = ({ data, refresh }) => {
  const { t } = useTranslation();
  const randomColor = () => {
    let style = [
      "avatar_color1",
      "avatar_color2",
      "avatar_color3",
      "avatar_color4",
    ];
    let random = Math.floor(Math.random() * style.length);
    let value = style[random];
    return { styles: value };
  };

  function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }

  const {
    setRoleId,
    setRoles,
    roles,
    setCreateRole,
    setCreateOrEditRole,
    setNameRol,
    setPermissionsRoles,
    setEnabledOrDisabledRole,
    setRoleState,
    setNewPermissionsRole,
    permissionsRoleUser,
  } = useContext(AuthContext);

  const [selectedRole, setSelectedRole] = useState(null);
  const [inputCleared, setInputCleared] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterRole, setFilterRole] = useState();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loadingText = open && options.length === 0;

  const [currentRolesPage, setCurrentRolesPage] = useState(1);
  const recordsPerPage = 10;
  const firstIndex = (currentRolesPage - 1) * recordsPerPage;
  const lastIndex = firstIndex + recordsPerPage;
  const records = roles.slice(firstIndex, lastIndex);
  const recordsIds = roles.map((roles) => roles.id);
  const totalRolesPages = Math.ceil(roles.length / recordsPerPage);
  const numbers = [...Array(totalRolesPages + 1).keys()].slice(1);

  useEffect(() => {
    if (data) {
      const filteredRoles = data.filter((role) =>
        role.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilterRole(filteredRoles);
      setCurrentRolesPage(1);
    }
  }, [data, searchTerm]);

  const openCreation = () => {
    setCreateRole(true);
    setCreateOrEditRole(t("VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.MODAL_CREATE_TOOLTIP"));
    setNameRol("");
  };

  const handleOpen = (id) => {
    setCreateRole(true);
    setCreateOrEditRole(
      t("VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.MODAL_EDIT_TOOLTIP")
    );
    setRoleId(id);
    const auxiliar = data.find((i) => i.id === id).components[0]
      .permissionsRole;
    setPermissionsRoles(auxiliar);
    setNewPermissionsRole(auxiliar);
    const prueba = data.find((i) => i.id === id);
    setNameRol(prueba.name);
  };

  const handleEnabled = (id) => {
    setEnabledOrDisabledRole(true);
    setRoleId(id);
    const aux = data.find((i) => i.id === id);
    setRoleState(aux.enabled);
  };

  useEffect(() => {
    let active = true;

    if (!loadingText) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);
      if (active) {
        const filteredOptions = data.filter((role) =>
          role.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setOptions([...filteredOptions]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingText, searchTerm]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const preRolesPage = () => {
    if (currentRolesPage !== 1) {
      setCurrentRolesPage(currentRolesPage - 1);
    }
  };

  const changeCurrentRolesPage = (id, name) => {
    setCurrentRolesPage(id);
    setSearchTerm(name);
  };

  const nextPage = () => {
    if (currentRolesPage !== totalRolesPages) {
      setCurrentRolesPage(currentRolesPage + 1);
    }
  };

  const handleInputChange = (event) => {
    const inputName = event.target.value;
    const matchingRole = data.find(
      (role) => role.name.toLowerCase() === inputName.toLowerCase()
    );
    if (matchingRole) {
      setSelectedRole(matchingRole);
    } else {
      setSelectedRole(null);
    }
    setSearchTerm(inputName);
    setInputCleared(false);
  };

  const filterRoled = records.filter((role) =>
    role.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAutocompleteChange = (event, value) => {
    if (value) {
      setSelectedRole(value);
      setSearchTerm(value.name);
    } else {
      setSelectedRole(null);
      setSearchTerm("");
      setInputCleared(true);
      setCurrentRolesPage(1);
    }
  };

  return (
    <>
      {permissionsRoleUser?.permissionsRole?.GesRolVer ? (
        <>
          <div className="roles_datatable_container">
            <div className="roles_container_header">
              <div className="roles_title_container">
                <h1 className="table_title">
                  {t(
                    "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.TABLE_TITLE"
                  )}
                </h1>
              </div>
              <div className="container_button_and_filter">
                <div className="content_header_button">
                  {permissionsRoleUser?.permissionsRole?.GesRolCrear ? (
                    <Tooltip
                      title={t(
                        "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.ADD_ROLE_BUTTON_TOOLIP"
                      )}
                    >
                      <button className="button_agreger" onClick={openCreation}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </Tooltip>
                  ) : null}
                </div>

                <div className="role_filter_container">
                  <Autocomplete
                    id="role_filter"
                    value={selectedRole}
                    inputValue={inputCleared ? "" : searchTerm}
                    disableClearable={true}
                    onChange={handleAutocompleteChange}
                    open={open}
                    onInput={handleInputChange}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(option) => option.name}
                    options={options}
                    loading={loadingText}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t(
                          "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.SEARCH_INPUT"
                        )}
                        size="small"
                        value={searchTerm}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loadingText ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                          sx: { borderRadius: 2 },
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="roles_container_table">
              <table className="roles_datatable">
                <thead className="roles_table_header">
                  <tr>
                    <th>
                      <h3 className="number_rol">No:</h3>
                    </th>
                    <th>
                      <h3 className="name_rol_title">
                        {t(
                          "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.ROLE"
                        )}
                      </h3>
                    </th>
                    <th>
                      <h3 className="state_rol">
                        {t(
                          "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.STATUS"
                        )}
                      </h3>
                    </th>
                    <th>
                      <h3 className="action_rol">
                        {t(
                          "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.EDIT"
                        )}
                      </h3>
                    </th>
                  </tr>
                </thead>
                {filterRoled.map((item, key) => (
                  <tbody className="roles_container_table_body">
                    <>
                      <tr>
                        <td className="container_number_rol">{`${key + 1}`}</td>
                        <td className="container_avatar_rol">{item.name}</td>
                        <td className="main_role_status_container">
                          <span
                            className={`${
                              item.enabled
                                ? "container_estado"
                                : "container_state_inactive"
                            }`}
                          >
                            {String(
                              item.enabled === false
                                ? t(
                                    "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.INACTIVE"
                                  )
                                : t(
                                    "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.ACTIVE"
                                  )
                            )}
                          </span>
                        </td>
                        <td className="main_container_roles">
                          <div className="roles_container_acctions">
                            {permissionsRoleUser?.permissionsRole
                              ?.GesRolEditar ? (
                              <Tooltip
                                title={t(
                                  "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.MODAL_EDIT_TOOLTIP"
                                )}
                              >
                                <ModeEditIcon
                                  className="edit_button"
                                  onClick={() => handleOpen(item.id)}
                                />
                              </Tooltip>
                            ) : null}

                            {permissionsRoleUser?.permissionsRole?.GesRolDes ? (
                              <RolesToggleSwitch
                                currentIdRole={item.id}
                                currentRoleStatus={item.enabled}
                              />
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    </>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
          <ModalRole refresh={refresh} data={data} />
          <ModalAlertChangesRoles refresh={refresh} data={data} />
        </>
      ) : null}

      <div className="roles_pagination">
        <Fab
          size="small"
          aria-label="left"
          className="roles_pagination_button"
          onClick={preRolesPage}
          disabled={currentRolesPage <= 1}
        >
          <ChevronLeftIcon />
        </Fab>
        <span>
          {currentRolesPage} / {totalRolesPages}
        </span>
        <Fab
          size="small"
          aria-label="right"
          className="roles_pagination_button"
          onClick={nextPage}
          disabled={
            currentRolesPage === totalRolesPages || totalRolesPages === 0
          }
        >
          <ChevronRightIcon />
        </Fab>
      </div>
    </>
  );
};
