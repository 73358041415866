import React from "react";
import styled from "styled-components";

export const MainContainerMacrometerVariables = styled.div`
  display: grid;
  grid-template-rows: 15% 15% 50% 25%;
  font-family: #1c1c1c;

  @media (max-width: 768px) {
    grid-template-rows: 10% 30% 55% 5%;
  }
`;

export const CancelInstallationButtonContainer = styled.div`
  display: flex;
  position: fixed;
  justify-content: flex-end;
  width: 45%;
`;

export const MacrometerVariablesTitle = styled.div`
  display: flex;
  .variables_title {
    font-weight: 500;
    font-size: 25px;
    width: 60%;
    @media (max-width: 425px) {
      font-size: 20px;
    }
  }
`;

export const MacrometerVariablesSecondaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;

  .text_description {
    flex-direction: column;
    justify-self: flex-start;
    align-self: center;
    font-size: 16px;
  }

  .first_input_group {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50%;
    width: 100%;
    margin-bottom: 15px;
  }

  .second_input_group {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50%;
    width: 100%;
  }
`;
