import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { PaginationContainer, Prev, Next } from "../styles/Pagination.style";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  UPDATE_NEW_MACROMETER,
  GET_NEW_INSTALLED_METTERS,
} from "../../../controllers/measurersController";
import { useHistory } from "react-router-dom";

const customGreen = "#acf6ac";
const customRed = "#F2B0B0";
const theme = createTheme({
  palette: {
    success: {
      main: customGreen,
    },
    warning: {
      main: customRed,
    },
  },
});

const Pagination = ({
  next,
  prev,
  labelPreviousButton,
  labelNextButton,
  previousButtonColor,
  nextButtonColor,
  enabled,
}) => {
  const history = useHistory();
  const [updateMacroMeter] = useMutation(UPDATE_NEW_MACROMETER);
  const [getData, { data, loading }] = useLazyQuery(GET_NEW_INSTALLED_METTERS, {
    fetchPolicy: "network-only",
  });

  const meterVariables = localStorage.getItem("editedMeterVariables")
    ? JSON.parse(localStorage.getItem("editedMeterVariables"))
    : localStorage.getItem("editedMeterVariables");

  const ShowMetersIcons = async () => {
    try {
      await getData({
        variables: {
          projectId: parseInt(localStorage.getItem("id")),
        },
      });
    } catch (err) {}

    if (labelNextButton === "Finalizar") {
      try {
        updateMacroMeter({
          variables: {
            meters: meterVariables,
          },
        }).catch((err) => {});
      } catch (err) {}
      hideMetersIcons();
    }
  };

  const hideMetersIcons = () => {
    if (labelPreviousButton === "Salir" || labelNextButton === "Finalizar") {
      localStorage.setItem("existNewMeters", "0");
      if (localStorage.getItem("existNewMeters") === "0") {
        localStorage.setItem("showMeters", false);
      }
      localStorage.setItem("meterVariables", "");
      localStorage.setItem("editedMeterVariables", "");
    }
  };

  const handleMeter = () => {
    const existsMeter = data?.getNewMeters?.meters.length;
    if (!data) return null;
    if (existsMeter > 0) {
      localStorage.setItem("showMeters", true);
      localStorage.setItem("existNewMeters", existsMeter);
      localStorage.setItem(
        "meterVariables",
        JSON.stringify(data?.getNewMeters?.meters)
      );
      history.push(next);
    } else {
      localStorage.setItem("showMeters", false);
      history.push(
        "/app/stepperviewmacrometer/description/errormacrometerinstallation"
      );
    }
  };

  return (
    <PaginationContainer data-testid="pagination">
      {loading ? "" : handleMeter()}
      {prev ? (
        <Prev>
          <Link to={prev}>
            <ThemeProvider theme={theme}>
              <Button
                onClick={hideMetersIcons}
                variant="contained"
                startIcon={
                  <NavigateBeforeRoundedIcon
                    sx={{
                      height: "27px",
                      width: "27px",
                      marginRight: "0px",
                      marginLeft: "0px",
                    }}
                  />
                }
                color={previousButtonColor}
                sx={{
                  color: "black",
                  borderRadius: "8px",
                }}
              >
                {labelPreviousButton}
              </Button>
            </ThemeProvider>
          </Link>
        </Prev>
      ) : (
        <Prev></Prev>
      )}

      {next ? (
        <Next>
          <ThemeProvider theme={theme}>
            <Button
              disabled={enabled}
              onClick={ShowMetersIcons}
              variant="contained"
              endIcon={
                <NavigateNextRoundedIcon
                  sx={{
                    height: "27px",
                    width: "27px",
                    marginRight: "0px",
                    marginLeft: "0px",
                  }}
                />
              }
              color={nextButtonColor}
              sx={{
                color: "black",
                borderRadius: "8px",
              }}
            >
              {labelNextButton}
            </Button>
          </ThemeProvider>
        </Next>
      ) : (
        <Next></Next>
      )}
    </PaginationContainer>
  );
};
export default Pagination;
