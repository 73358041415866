import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import {
  MainContainerMacrometerVariables,
  MacrometerVariablesTitle,
  MacrometerVariablesSecondaryContainer,
} from "../styles/MacrometerVariables.style";
import Pagination from "../components/Pagination";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CancelInstallationButton from "../components/CancelInstallationButton";
import CancelInstallationModal from "../components/CancelInstallationModal";

const secondaryGreen = "#67A46D";
const theme = createTheme({
  palette: {
    success: {
      main: secondaryGreen,
    },
  },
});

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const MacrometerVariables = ({ info }) => {
  const allMetersVariables = JSON.parse(localStorage.getItem("meterVariables"));
  const existMeters = parseInt(localStorage.getItem("existNewMeters"));
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [variables, setVaribles] = useState(allMetersVariables);
  const [validVariables, setValidVariables] = useState(false);
  localStorage.setItem("editedMeterVariables", JSON.stringify(variables));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handlerMeterAlias = (event, index) => {
    const value = event.target.value;
    const regex = /^[a-zA-ZñÑáÁéÉíÍóÓúÚ.0-9\s]*$/;
    if (regex.test(value)) {
      setVaribles((prevVariables) =>
        prevVariables.map((item, idx) =>
          idx === index ? { ...item, alias: value } : item
        )
      );
    }
  };

  const handlerMeterTcRelation = (event, index) => {
    const value = event.target.value;
    const regex = /^[0-9/\s]*$/;
    if (regex.test(value)) {
      const variablesOperation = value.split("/");
      const outcome = variablesOperation[0] / variablesOperation[1];
      setVaribles((prevVariables) =>
        prevVariables.map((item, idx) =>
          idx === index
            ? { ...item, relationTc: value, powerFactor: outcome }
            : item
        )
      );
    }
  };

  const handlerMeterMaxCurrent = (event, index) => {
    const value = event.target.value;
    const regex = /^[0-9/\s]*$/;
    if (regex.test(value)) {
      setVaribles((prevVariables) =>
        prevVariables?.map((item, idx) =>
          idx === index ? { ...item, maximumCurrent: value } : item
        )
      );
    }
  };

  useEffect(() => {
    if (variables) {
      localStorage.setItem("editedMeterVariables", JSON.stringify(variables));
      const inValidVariables = variables?.find(
        (meter) =>
          meter?.alias?.length < 3 ||
          !meter?.relationTc?.includes("/") ||
          !Number?.isInteger(meter?.powerFactor) ||
          parseInt(meter?.maximumCurrent) < 100 ||
          parseInt(meter?.maximumCurrent) > 5000
      );
      if (inValidVariables) {
        setValidVariables(true);
      } else {
        setValidVariables(false);
      }
    }
  }, [variables]);

  return (
    <MainContainerMacrometerVariables>
      <MacrometerVariablesTitle>
        <span className="variables_title">
          {"Variables de/los medidor(es)"}
        </span>
        <CancelInstallationButton />
      </MacrometerVariablesTitle>
      <MacrometerVariablesSecondaryContainer>
        <div className="text_description">{info.description}</div>
      </MacrometerVariablesSecondaryContainer>
      <MacrometerVariablesSecondaryContainer>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="basic tabs example"
            >
              {allMetersVariables.map((meter, index) => (
                <Tab
                  label={"Macromedidor " + (index + 1)}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {allMetersVariables?.map((meter, index) => (
              <CustomTabPanel value={value} index={index} dir={theme.direction}>
                <div className="first_input_group">
                  <ThemeProvider theme={theme}>
                    <TextField
                      sx={{}}
                      size="small"
                      variant="outlined"
                      label={"Alias (nombre)"}
                      InputProps={{ sx: { borderRadius: 2 } }}
                      color={
                        variables[index]?.alias.length >= 3
                          ? "success"
                          : "error"
                      }
                      disabled={existMeters > 0 ? false : true}
                      value={variables[index]?.alias}
                      onChange={(event) => handlerMeterAlias(event, index)}
                    />
                    <TextField
                      sx={{}}
                      size="small"
                      variant="outlined"
                      label="Relación TC"
                      InputProps={{ sx: { borderRadius: 2 } }}
                      color={
                        variables[index]?.relationTc.includes("/") &&
                        Number.isInteger(variables[index]?.powerFactor)
                          ? "success"
                          : "error"
                      }
                      disabled={existMeters > 0 ? false : true}
                      value={variables[index]?.relationTc}
                      onChange={(event) => handlerMeterTcRelation(event, index)}
                    />
                  </ThemeProvider>
                </div>
                <div className="second_input_group">
                  <ThemeProvider theme={theme}>
                    <TextField
                      sx={{}}
                      size="small"
                      variant="outlined"
                      label={"Factor de potencia"}
                      InputProps={{ sx: { borderRadius: 2 } }}
                      color="success"
                      disabled={true}
                      value={variables[index]?.powerFactor}
                    />
                    <TextField
                      sx={{}}
                      size="small"
                      variant="outlined"
                      label="Maxima Corriente"
                      InputProps={{ sx: { borderRadius: 2 } }}
                      color={
                        parseInt(variables[index]?.maximumCurrent) >= 100 &&
                        parseInt(variables[index]?.maximumCurrent) <= 5000
                          ? "success"
                          : "error"
                      }
                      disabled={existMeters > 0 ? false : true}
                      value={variables[index]?.maximumCurrent}
                      onChange={(event) => handlerMeterMaxCurrent(event, index)}
                    />
                  </ThemeProvider>
                </div>
              </CustomTabPanel>
            ))}
          </SwipeableViews>
        </Box>
      </MacrometerVariablesSecondaryContainer>
      <Pagination
        labelPreviousButton={"Atrás"}
        previousButtonColor={"success"}
        nextButtonColor={"success"}
        labelNextButton={"Siguiente"}
        enabled={validVariables}
        prev={info.prev}
        next={info.next}
      />
      <CancelInstallationModal />
    </MainContainerMacrometerVariables>
  );
};

export default MacrometerVariables;
